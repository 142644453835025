<template>
  <v-overlay :value="overlay" :color="colorOverlay" opacity="1" :class="{ 'new-class': hasNewClass }" z-index="9">
    <div class="loading-wrap text-center">
      <div class="loading-brand">
        <img src="@/assets/logo_dark.png" alt="Relevent" width="180" v-if="dark">
        <img src="@/assets/logo_light.png" alt="Relevent" width="180" v-else>
      </div> <!-- .brand -->

      <div class="loading-progress mt-2">
        <v-progress-circular :size="size" :value="0" :width="width" color="#2D2F30">
          <v-progress-circular :size="size" indeterminate color="grey lighten-1" :width="width" class="" />
        </v-progress-circular>
      </div> <!-- .progress -->

    </div>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ["value"],

  data: () => ({
    width: 2,
    size: 27,
    hasNewClass: false,
    overlay: true,
  }),

  watch: {
    value() {
      this.close()
    }
  },

  computed: {
    ...mapState("config", ["dark"]),

    colorOverlay() {
      return this.dark ? '#131517' : '#f8f9fd'
    }
  },

  methods: {
    close() {
      setTimeout(() => {
        this.hasNewClass = true;

        setTimeout(() => {
          this.overlay = false;
        }, 200); // 1000 milisegundos = 1 segundo

      }, 200); // 3000 milisegundos = 3 segundos
    }
  },
}
</script>


<style lang="scss" scoped>
.loading-progress,
img {
  transition: 0.2s ease;
}

.new-class {
  img {
    transform: translate(0, -20px);
    opacity: 0;
  }

  .loading-progress {
    transform: translate(0, 50px);
    opacity: 0;
  }
}
</style>