let app;
import _ from "lodash";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { auth } from "./firebase"; // firebase
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";
moment.locale("es");

Vue.config.productionTip = false;

onAuthStateChanged(auth, async (userAuth) => {
  // Get user data
  if (userAuth) {
    let tokenResult = await userAuth.getIdTokenResult();
    let role = _.pick(tokenResult.claims, ["admin", "owner", "seller", "scan"]);

    store.commit("auth/setUser", {
      companyId: tokenResult.claims.companyId,
      displayName: userAuth.displayName,
      email: userAuth.email,
      avatar: userAuth.photoURL,
      uid: userAuth.uid,
      role: Object.keys(role).toString(),
      ...role,
    });
  }

  // Start app
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
