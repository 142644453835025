const theme = window.localStorage.getItem('theme');
export default {
  namespaced: true,
  state: {
    dark: JSON.parse(theme),
    mobile: false,
    warning: false,
    disabled: false,
  },
  mutations: {
    setDark(state, payload) {
      state.dark = payload;
      window.localStorage.setItem('theme', payload);
    },
    setMobile(state, payload) {
      state.mobile = payload;
    },
    setWarning(state, payload) {
      state.warning = payload;
    },
    setDisabled(state, payload) {
      state.disabled = payload;
    },
  },
};
