import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyCl1cVBUMIB0azlhXi_JJtb84UxNtO14jY",
    authDomain: "relevent-d2924.firebaseapp.com",
    projectId: "relevent-d2924",
    storageBucket: "relevent-d2924.appspot.com",
    messagingSenderId: "83896155465",
    appId: "1:83896155465:web:c468e0a16fecab4710ee98",
    measurementId: "G-82RTPT6VPQ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore();
const functions = getFunctions();

if (process.env.NODE_ENV === 'development_') {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectFirestoreEmulator(db, 'localhost', 8081);
}

export { auth, db, functions, analytics }