import { auth, db } from "@/firebase";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

// const userLocal = window.localStorage.getItem("user");

export default {
  namespaced: true,
  state: {
    user: {},
    // user: JSON.parse(userLocal),
    company: {},
    desktop: false,
    subscription: [],
  },
  mutations: {
    set__company(state, payload) {
      state.company = payload;
    },
    set_desktop(state, payload) {
      state.desktop = payload;
    },

    setUser(state, payload) {
      state.user = payload;
    },

    SET_SUBSCRIPTION(state, payload) {
      state.subscription = payload;
    },
  },
  actions: {
    async getCompany({ state, commit, dispatch }) {
      try {
        // const
        const companyId = state.user.companyId;

        // references
        const companyDocRef = doc(db, "companies", companyId);

        // query
        const docSnap = await getDoc(companyDocRef);

        // process
        if (docSnap.exists()) {
          const companyData = docSnap.data();

          // setData
          commit("set__company", companyData);
          commit("config/setWarning", companyData.warning, { root: true });
          commit("config/setDisabled", companyData.disabled, { root: true });
        } else {
          dispatch("signOut");
        }
      } catch (error) {
        console.error(error);
        dispatch("signOut");
      }
    },

    // [user-out]
    signOut({ commit }) {
      signOut(auth)
        .then(() => {
          commit("setUser", {});
          commit("set__company", {});
          commit("setScanMode", null, { root: true });

          window.localStorage.removeItem("scanMode");
        })
        .catch((error) => {
          alert("An error happened: ", error);
        });
    },
  },
};
