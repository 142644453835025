import Vue from "vue";
import Vuex from "vuex";

// Import Modules Store
import config from "./modules/config";

import auth from "./modules/auth";
import events from "./modules/events";
import attendees from "./modules/attendees";
import staff from "./modules/staff";

// Modules
import lists from "./modules/lists";

Vue.use(Vuex);

const scanMode = window.localStorage.getItem("scanMode");

export default new Vuex.Store({
  state: {
    mode: JSON.parse(scanMode),
    desktop: false,
    isRefresh: false,
  },

  mutations: {
    set_desktop(state, payload) {
      state.desktop = payload;
    },
    setIsRefresh(state, payload) {
      state.isRefresh = payload;
    },
    setScanMode(state, payload) {
      state.mode = payload;
      window.localStorage.setItem("scanMode", JSON.stringify(payload));
    },
  },

  actions: {
    selectedMode({ commit }, payload) {
      console.debug("MODE: ", payload);
      commit("setScanMode", payload);
    },
  },

  modules: {
    config,
    auth,
    events,
    attendees,
    staff,
    lists,
  },
});
