import {mapState} from "vuex"

// src/mixins/navigation.js

export default {
  data: () => ({
    items: [
      {title: 'Eventos', icon: 'mdi-calendar', to: {name: "MyEvents"}, auth: ["owner", "seller"],},
      // events > history
      {
        title: 'Historial Eventos',
        icon: 'mdi-calendar-multiple',
        to: {name: "EventsHistory"},
        auth: ["owner", "seller"]
      },

      {
        title: 'Asistentes Especiales',
        icon: 'mdi-account-star-outline',
        to: {name: "attendees-specials"},
        auth: ["owner"]
      },
      {title: 'Asistentes Bloqueados', icon: 'mdi-account-cancel-outline', to: {name: "BlackList"}, auth: ["owner"]},
      {title: 'Staff Empresa', icon: 'mdi-account-group', to: {name: "Staff"}, auth: ["owner"]},
      {title: 'Pagos', icon: 'mdi-invoice-list-outline', to: {name: "invoice"}, auth: ["owner"]},
      //

    ],
  }),

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    allowed(auth) {
      const role = this.user.role
      return auth.includes(role)
    }
  },
}