<template>
  <v-navigation-drawer app floating :value="value" @input="$emit('input', $event)" :width="setWidth" :color="setColor">

    <template v-slot:prepend>
      <div id="drawer-brand" class="px-6 pt-9 d-flex align-center justify-space-between">

        <!-- Logo -->
        <div class="drawer-brand_logo d-flex">
          <img src="@/assets/logo_dark.png" alt="Relevent" width="180" v-if="dark">
          <img src="@/assets/logo_light.png" alt="Relevent" width="180" v-else>
        </div>

        <!-- Button: Close -->
        <v-btn icon @click="$emit('input', false)" v-if="mobile" class="grey lighten-3">
          <v-icon color="grey darken-3">mdi-close</v-icon>
        </v-btn>
      </div>
    </template> <!-- #drawer-brand -->

    <!-- company & scan -->
    <div class="px-6 | mt-12">
      <v-card outlined>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle class="font-weight-medium">
              Empresa:
            </v-list-item-subtitle>
            <v-list-item-title>
              <span class="text-h6 font-weight-semibold | text-uppercase" v-text="company.name"/>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <!-- scan -->
      <v-btn-toggle style="width: 100%" class="mt-4" v-if="user.role === 'scan'">
        <v-btn style="width: 50%" :color="mode ? null : 'grey'" @click="selectedMode(false)">
          Camara
        </v-btn>
        <v-btn style="width: 50%" :color="mode ? 'grey' : null" @click="selectedMode(true)">
          Escáner
        </v-btn>
      </v-btn-toggle>
    </div>

    <!-- navigations items -->
    <v-list flat class="mt-6">
      <v-list-item-group color="primary">
        <template v-for="(item, i) in items">
          <v-list-item class="px-6 rv-item" :key="i" :to="item.to" active-class="rv-item--active"
                       v-if="allowed(item.auth)" :disabled="disabled">
            <v-list-item-icon>
              <v-icon v-text="item.icon"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" class="font-weight-medium text-subtitle-1"/>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>

    <!-- footer: sign-out -->
    <template v-slot:append>


      <div class="px-4 py-3">
        <theme-select/>
      </div>

      <v-divider></v-divider>
      <div class="pa-2">
        <v-btn plain block @click="onClose">Cerrar Sesión</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";
import navigation from "../../mixins/navigation";

import ThemeSelect from "./ThemeSelect.vue"

export default {
  props: ["value"],

  mixins: [navigation],

  components: {ThemeSelect},


  computed: {
    ...mapState(["mode"]),
    ...mapState("auth", ["user", "company"]),

    // v2
    ...mapState("config", ["dark", "mobile", "disabled"]),

    setWidth() {
      return this.mobile ? "100%" : "300"
    },

    setColor() {
      return this.dark ? "#1a1b1f" : null
    }
  },

  methods: {
    ...mapActions(["selectedMode"]),
    ...mapActions("auth", ["signOut"]),

    onClose() {
      if (confirm("Seguro que quieres salir")) {
        this.signOut();
      }
    },
  },
};
</script>