<script>
export default {
  props: ['disabled', 'warning']
}
</script>

<template>
  <v-alert prominent :type="disabled ? 'error' : 'warning'" class="rounded-0" v-if="warning || disabled">

    <h6 class="text-h6 font-weight-medium">
      <span v-if="warning ">Pendiente de Pago</span>
      <span v-else>Servicio Suspendido</span>
    </h6> <!-- #title -->

    <p class="mb-0" v-if="warning">¡Atención! Su suscripción ha vencido y está próxima a caducar. Regularícela para
      evitar la cancelación del servicio.</p>

    <p class="mb-0" v-else>¡Urgente! Su servicio está suspendido debido a una suscripción vencida. Tome acción para
      reactivarlo y evitar inconvenientes.</p>
  </v-alert>
</template>

<style scoped lang="scss">
</style>