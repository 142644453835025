import _ from "lodash";
import { db } from "@/firebase";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";

export default {
  namespaced: true,
  state: {
    lists: [],
  },
  mutations: {
    setLists(state, payload) {
      state.lists = payload;
    },
  },
  actions: {
    async create({ rootState, dispatch }, payload) {
      const companyId = rootState.auth.user.companyId;
      const reference = `companies/${companyId}/events/${payload.eventId}/lists`;

      // Add a new document with a generated id
      const newList = doc(collection(db, reference));

      // later...
      await setDoc(newList, _.pick(payload, ["name", "date", "order"]));

      dispatch("reader", payload.eventId);
    },

    async reader({ rootState, commit }, eventId) {
      let items = [];
      const companyId = rootState.auth.user.companyId;
      const reference = `companies/${companyId}/events/${eventId}/lists`;

      const q = query(collection(db, reference), orderBy("order"));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        items.push(_.merge({ docId: doc.id }, doc.data()));
      });

      commit("setLists", items);
    },

    async updated() {},

    async delete({ rootState, dispatch }, payload) {
      const companyId = rootState.auth.user.companyId;
      const reference = `companies/${companyId}/events/${payload.eventId}/lists`;

      await deleteDoc(doc(db, reference, payload.docId));

      dispatch("reader", payload.eventId);
    },
  },
};
