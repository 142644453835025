import _ from 'lodash'
import moment from 'moment';
import { db } from '@/firebase';
import { collection, query, where, getDocs, addDoc, doc, getDoc, updateDoc } from "firebase/firestore";

export default {
    namespaced: true,
    state: {
        myEvent: {},
        myEvents: []
    },
    mutations: {
        set_myEvent(state, payload) { state.myEvent = payload },
        set_myEvents(state, payload) { state.myEvents = payload }
    },
    actions: {

        // Get Event
        async getEvent({ rootState }, eventId) {

            const user = rootState.auth.user
            const docRef = doc(db, `/companies/${user.companyId}/events/`, eventId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

                const data = docSnap.data();
                const list = _.map(data.list, 'name');
                data.lists = _.concat(data.lists, list);

                return _.merge({ uid: docSnap.id }, data)
            } else {
                alert("Evento no Existe");
            }
        },






        // [create-event]
        async createEvent({ rootState, state }, payload) {

            const company_id = rootState.auth.user.companyId;
            const collectionRef = `companies/${company_id}/events`;

            const docRef = await addDoc(collection(db, collectionRef), payload)

            // * Create List
            const refLists = `companies/${company_id}/events/${docRef.id}/lists`;

            await addDoc(collection(db, refLists), {
                name: "General",
                order: 1
            });


            // Get New Event
            const getRef = doc(db, collectionRef, docRef.id);
            const docSnap = await getDoc(getRef);




            if (docSnap.exists()) {
                state.myEvents.push(_.merge({ uid: docSnap.id }, docSnap.data()))
            } else {
                console.log("No such document!");
            }

        },

        async readerEvent({ rootState, commit }, eventId) {

            const user = rootState.auth.user
            const docRef = doc(db, `/companies/${user.companyId}/events/`, eventId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                commit("set_myEvent", _.merge({ uid: docSnap.id }, docSnap.data()))
            } else {
                alert("Evento no Existe");
            }
        },

        async readerEvents({ rootState, commit }) {

            const user = rootState.auth.user;
            const events = [];
            const dateLimit = moment().subtract(1, 'days').format("YYYY-MM-DD"); // Yesterday

            const q = query(collection(db, `companies/${user.companyId}/events`), where("dateStart", ">=", dateLimit));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                events.push(_.merge({ uid: doc.id }, doc.data()))
            });

            commit("set_myEvents", events);
        },

        async updateEventState({ rootState }, payload) {
            const user = rootState.auth.user
            const reference = `/companies/${user.companyId}/events/`;

            const updateStateEvent = doc(db, reference, payload.uid);

            await updateDoc(updateStateEvent, {
                active: payload.active
            });
        },

        // v2.3
        async eventsActive({ rootState, commit }) {

            const e = []
            const u = rootState.auth.user;

            const q = query(collection(db, `companies/${u.companyId}/events`), where("finishDate", ">=", new Date()));

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                e.push(_.merge({ uid: doc.id }, doc.data()))
            });


            commit("set_myEvents", e);
            return e
        },

        async eventsPast({ rootState, commit }) {

            const e = []
            const u = rootState.auth.user;

            const q = query(collection(db, `companies/${u.companyId}/events`), where("finishDate", "<=", new Date()));

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                e.push(_.merge({ uid: doc.id }, doc.data()))
            });

            commit("set_myEvents", e);
        }
    },
    getters: {
        eventState: (state) => (active) => {
            return _.filter(state.myEvents, { 'active': active });
        }
    }
}