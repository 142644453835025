let unsubscribe;
import _ from "lodash";
import { db } from "@/firebase";
import { collection, addDoc, query, onSnapshot, doc, deleteDoc } from "firebase/firestore";

export default {
    namespaced: true,
    state: {
        staff: []
    },
    mutations: {
        setStaff(state, payload) { state.staff = payload }
    },
    actions: {
        async create({ rootState }, payload) {
            const companyId = rootState.auth.user.companyId;
            await addDoc(collection(db, `companies/${companyId}/staff`), payload);
        },

        async reader({ rootState, commit }) {

            const companyId = rootState.auth.user.companyId;
            const reference = `companies/${companyId}/staff`;
            const q = query(collection(db, reference));

            return new Promise(resolve => {
                unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const staff = [];
                    querySnapshot.forEach((doc) => {
                        staff.push(_.merge({ docId: doc.id }, doc.data()));
                    });
                    commit("setStaff", staff);
                    resolve();
                });
            })


        },

        updated() { },

        async delete({ rootState }, docId) {

            const companyId = rootState.auth.user.companyId;
            const reference = `companies/${companyId}/staff`;
            await deleteDoc(doc(db, reference, docId));

        },

        setUnsubscribe() {
            unsubscribe()
        },
    },
}