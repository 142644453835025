<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState("config", ["dark"])
  },

  methods: {
    selected(value) {
      this.$vuetify.theme.dark = value
      this.$store.commit('config/setDark', value)
    }
  }
}
</script>

<template>
  <div id="c-theme-select" class="pa-2 d-flex justify-space-between">
    <v-btn text large :class="{ active: !dark }" @click="selected(false)">
      <v-icon small class="mr-2">mdi-white-balance-sunny</v-icon>
      Light
    </v-btn>
    <v-btn text large :class="{ active: dark }" @click="selected(true)">
      <v-icon small class="mr-2">mdi-moon-waning-crescent</v-icon>
      Dark
    </v-btn>
  </div>
</template>

<style scoped lang="scss">
#c-theme-select {

  border-radius: 6px;
  background-color: #f5f5f5;

  .v-btn {
    width: 48%;
    color: #8b8b8b;

    &.active {
      color: black;

      // theme-light
      @at-root .theme--light & {
        background-color: white;
      }
    }
  }

  // theme: dark
  @at-root .theme--dark & {
    background-color: #0A0A0A;

    .v-btn {
      color: #6c7274;

      &.active {
        color: white;
        background-color: #141618;
      }
    }
  }
}
</style>