<template>
  <component :is="this.$route.meta.layout || 'div'">
    <router-view/>
  </component>
</template>

<script>
import moment from "moment";

export default {
  name: "App",

  methods: {
    setTheme() {

      const currentTime = moment();
      const morningTime = moment().hour(10).minute(0);
      const eveningTime = moment().hour(19).minute(0);

      let ThemeSelect = window.localStorage.getItem('theme');
      ThemeSelect = JSON.parse(ThemeSelect)

      if (ThemeSelect === null) {
        if (currentTime.isBefore(morningTime) || currentTime.isAfter(eveningTime)) {
          this.$vuetify.theme.dark = true
          this.$store.commit('config/setDark', true)
        }
      } else {
        this.$vuetify.theme.dark = this.$store.state.config.dark
      }


    },

    setMobile() {
      this.$store.commit("set_desktop", this.$vuetify.breakpoint.lgAndUp);
      this.$store.commit('config/setMobile', this.$vuetify.breakpoint.mobile)
    }
  },

  beforeMount() {
    this.setTheme();
    this.setMobile();
  },

};
</script>

<style lang="scss">
@import "@/sass/custom.scss";
</style>